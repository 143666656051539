import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
import "../css/app.css";

if (!location.href.includes("localhost")) {
  document.getElementById("header-col-1")?.remove();
  document.getElementById("header-col-2")?.remove();
}

function clearBannerDots(dot_className) {
  let dots = document.getElementsByClassName(dot_className);
  for (let index = 0; index < dots.length; index++) {
    const dot = dots[index];
    dot.classList.remove("active");
  }
}

function makeBannerDotActive(index, dot_className) {
  clearBannerDots(dot_className);
  let dots = Array.from(document.getElementsByClassName(dot_className));
  dots[index]?.classList.toggle("active");
}

function onSliderDotClick(e, slider, dot_className) {
  const index = parseInt(e.currentTarget.dataset.slide, 10);
  slider.moveToIdx(index);
  makeBannerDotActive(index, dot_className);
}

function setupSliderNavigation(
  slider,
  prevButtonId,
  nextButtonId,
  makeBannerDotActive,
  dot_className //the className of the SVG inside the button
) {
  let arrowLeft = document.getElementById(prevButtonId);
  let arrowRight = document.getElementById(nextButtonId);

  arrowLeft.addEventListener("click", () => {
    slider.prev();
    updateActiveDotLeft(slider, makeBannerDotActive, dot_className);
  });

  arrowRight.addEventListener("click", () => {
    slider.next();
    updateActiveDotRight(slider, makeBannerDotActive, dot_className);
  });
}

function updateActiveDotLeft(slider, makeBannerDotActive, dot_className) {
  let slide = slider.track.details.rel;
  let index = 0;

  switch (slide) {
    case 0:
      index = slider.track.details.slides.length - 1;
      break;
    case slider.track.details.slides.length:
      index = 0;
      break;
    default:
      index = slide - 1;
      break;
  }

  makeBannerDotActive(index, dot_className);
}

function updateActiveDotRight(slider, makeBannerDotActive, dot_className) {
  let slide = slider.track.details.rel;
  let index = 0;

  switch (slide) {
    case 0:
      index = slide + 1;
      break;
    case slider.track.details.slides.length - 1:
      index = 0;
      break;
    default:
      index = slide + 1;
      break;
  }

  makeBannerDotActive(index, dot_className);
}

// Homepage banner settings only
function autoplay(slider) {
  let timeout
  let mouseOver = false

  function clearNextTimeout() {
    clearTimeout(timeout)
  }

  function nextTimeout() {
    clearTimeout(timeout)
    if (mouseOver) return
    timeout = setTimeout(() => {
      slider.next()
    }, 4000)
  }

  slider.on("created", () => {
    slider.container.addEventListener("mouseover", () => {
      mouseOver = true
      clearNextTimeout()
    })
    slider.container.addEventListener("mouseout", () => {
      mouseOver = false
      nextTimeout()
    })

    nextTimeout()
  })
  slider.on("dragStarted", clearNextTimeout)
  slider.on("animationEnded", nextTimeout)
  slider.on("updated", nextTimeout)
}

function navigation(slider) {
  let dots, arrowLeft, arrowRight;

  function markup(remove) {
    dotMarkup(remove);
    arrowMarkup(remove);
  }

  function createDiv(className) {
    let div = document.createElement("div");
    let classNames = className.split(" ");
    classNames.forEach((name) => div.classList.add(name));
    return div;
  }

  function arrowMarkup() {
    arrowLeft = document.getElementById("banner-prev");
    arrowLeft.addEventListener("click", () => slider.prev());
    arrowRight = document.getElementById("banner-next");
    arrowRight.addEventListener("click", () => slider.next());
  }

  function dotMarkup() {
    dots = document.getElementById("slider-dots");
    slider.track.details.slides.forEach((_e, idx) => {
      let dot = createDiv("slider-dot-button");
      dot.addEventListener("click", () => slider.moveToIdx(idx));
      dots.appendChild(dot);
    });
  }

  function updateClasses() {
    let slide = slider.track.details.rel;
    slide === 0
        ? arrowLeft.classList.add("arrow--disabled")
        : arrowLeft.classList.remove("arrow--disabled");
    slide === slider.track.details.slides.length - 1
        ? arrowRight.classList.add("arrow--disabled")
        : arrowRight.classList.remove("arrow--disabled");
    let dots = document.querySelectorAll(".slider-dot");
    let qty; // Created variable to storage quantity/number slides
    dots.forEach(function (dot, idx) {
      qty = idx;
      idx === slide ?
          dot.classList.add("active") :
          dot.classList.remove("active");
    });
  }

  slider.on("created", () => {
    markup();
    updateClasses();
  });
  slider.on("optionsChanged", () => {
    console.log(2);
    markup(true);
    markup();
    updateClasses();
  });
  slider.on("slideChanged", () => {
    updateClasses();
  });
  slider.on("destroyed", () => {
    markup(true);
  });
}

if (document.getElementById("hero-banner-slider")) {
  let slider = new KeenSlider("#hero-banner-slider", {
    loop: true,
  }, [autoplay, navigation]
  );

  uncloakBannerSlides(slider.slides);

  let dots = Array.from(document.getElementsByClassName("slider-dot-button"));

  dots.forEach((dot) => {
    dot.addEventListener("click", (e) =>
        onSliderDotClick(e, slider, "slider-dot")
    );
  });

}

if (document.getElementById("event-slider")) {
  let slider = new KeenSlider("#event-slider", {
    loop: true,
    breakpoints: {
      "(min-width: 1023px)": {
        disabled: true,
      },
      "(min-width: 1024px)": {
        slides: {
          perView: 3,
          spacing: 15,
        },
      },
    },
  });

  setupSliderNavigation(
    slider,
    "event-prev",
    "event-next",
    makeBannerDotActive,
    "event-slider-dot"
  );

  // get all the slider dot buttons
  let dots = Array.from(
    document.getElementsByClassName("event-slider-dot-button")
  );

  dots.forEach((dot) => {
    dot.addEventListener("click", (e) =>
      // e is the event (for the target), slider is the whole slider, the string is the class name for the slider dot SVG
      onSliderDotClick(e, slider, "event-slider-dot")
    );
  });
}

if (document.getElementById("research-slider")) {
  // slider id
  // next button
  // prev button
  // slider dot <button> class
  // slider dot SVG class
  const sliderID = "#research-slider";
  const nextButton = "research-next";
  const prevButton = "research-prev";
  const dotButton = "research-slider-dot-button";
  const dotSVG = "research-slider-dot";

  let slider = new KeenSlider(sliderID, {
    loop: true,
    breakpoints: {
      "(min-width: 1023px)": {
        slides: { perView: 0, spacing: 0 },
      },
      "(min-width: 1024px)": {
        slides: {
          perView: 3,
          spacing: 15,
        },
      },
    },
  });

  setupSliderNavigation(
    slider,
    prevButton,
    nextButton,
    makeBannerDotActive,
    dotSVG
  );

  // get all the slider dot buttons
  let dots = Array.from(document.getElementsByClassName(dotButton));

  dots.forEach((dot) => {
    dot.addEventListener("click", (e) =>
      // e is the event (for the target), slider is the whole slider, the string is the class name for the slider dot SVG
      onSliderDotClick(e, slider, dotSVG)
    );
  });
}

function ThumbnailPlugin(main) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          main.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      addActive(slider.track.details.rel);
      addClickEvents();
      main.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

if (document.getElementById("thumbnail-slider")) {
  let lgSlider = new KeenSlider("#large-slider", {
    slides: {
      perView: 1,
    },
  });
  //thumbnail-prev
  //thumbnail-next
  let thumbnailPrev = document.getElementById("thumbnail-prev");
  thumbnailPrev.addEventListener("click", () => {
    lgSlider.prev();
  });

  let thumbSlider = new KeenSlider(
    "#thumbnail-slider",
    {
      slides: {
        perView: 6,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(lgSlider)]
  );
}

if (document.getElementById("mega-menu")) {
  // make the nav bar stick to the top of the page on scroll
  window.onscroll = function () {
    stickyNav();
    stickyVenueHire();
  };

  var navbar = document.getElementById("mega-menu");
  var venueHireButton = document.getElementById("venue-hire-button");

  var sticky = navbar.offsetTop;

  function stickyNav() {
    if (window.scrollY >= sticky) {
      navbar.classList.add("sticky-main-nav");
    } else {
      navbar.classList.remove("sticky-main-nav");
    }
  }
}

function stickyVenueHire() {
  if (venueHireButton) {
    if (window.scrollY >= sticky) {
      venueHireButton.classList.add("sticky-venue-button");
    } else {
      venueHireButton.classList.remove("sticky-venue-button");
    }
  }
}

function uncloakBannerSlides(slides) {
  for (let i = 0; i < slides.length; i++) {
    slides[i].removeAttribute("x-cloak");
  }
}

if (document.getElementById("image-slider")) {
  let slider = new KeenSlider("#image-slider", {
    loop: true,

    breakpoints: {
      "(min-width: 1023px)": {
        slides: { perView: 0, spacing: 0 },
      },
      "(min-width: 1024px)": {
        slides: {
          perView: 2,
          spacing: 5,
        },
      },
    },
  });

  let arrowLeft = document.getElementById("image-slider-prev");
  let arrowRight = document.getElementById("image-slider-next");

  arrowLeft.addEventListener("click", () => {
    slider.prev();
  });

  arrowRight.addEventListener("click", () => {
    slider.next();
  });
}

// look for the subscription form and if we find it, attempt to fill the email field
if (document.querySelector('[data-handle="subscribe"]')) {
  // if there's a session value for 'footerSubscribeEmail', use it to populate the email input
  if (sessionStorage.hasOwnProperty("footerSubscribeEmail")) {
    if (document.getElementById("form-input-email")) {
      document.getElementById("form-input-email").value =
        sessionStorage.footerSubscribeEmail;
    }

    sessionStorage.removeItem("footerSubscribeEmail");
  }
}

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    //move the recaptcha badge from the bottom right of the screen to the form
    console.log(document.getElementsByClassName("grecaptcha-badge"));
    if (document.querySelector("[data-freeform-controls]")) {
      console.log("got badge");
      let badge = document.getElementsByClassName("grecaptcha-badge")[0];
      let newParent = document.querySelector("[data-freeform-controls]");

      newParent.prepend(badge);
    }
  }
};

function moveCapchaBadge() {
  let badge = document.getElementsByClassName("grecaptcha-badge")[0];
  let newParent = document.querySelector("[data-freeform-controls]");
  badge.style["position"] = "static";
  newParent.prepend(badge);
}

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    // once the dom load is complete we need to find the recaptcha badge so we can move it from
    // the bottom right of the screen to inside the form next to the Submit button
    const targetNode = document.body;

    // Set up the observer configuration
    const config = { childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function (mutationsList, observer) {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const htmlCollection =
            document.getElementsByClassName("grecaptcha-badge");

          if (htmlCollection[0] != null) {
            // found the badge so disconnect the observer
            observer.disconnect();
            moveCapchaBadge();
            break;
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    // if(document.getElementById('research-slider')) {
    //   let eventTitles = Array.from(document.getElementsByClassName('research-cta-title'));
    //   let titleHeights = eventTitles.map(e => e.clientHeight);

    //   for (let index = 0; index < eventTitles.length; index++) {
    //     const element = eventTitles[index];
    //     element.style.height = titleHeights.sort()[titleHeights.length - 1] + 'px';
    //   }
    // }
  }
};

if (document.getElementById("header-contact-number")) {
  const iframe = document.getElementById("header-contact-number");
  const doc = iframe.contentDocument || iframe.contentWindow.document;

  doc.open();

  if (window.location.href.includes('venue-hire')) {
    doc.write(
      `<html style="overflow: hidden">
        <head></head>
          <body style="margin-top: 6px;margin-right: 0;">
              <div style="text-align: end;font-family: sans-serif; font-size: 1.7rem;" class="text-end font-helvetica-neue-light" id="ah-contact-number">+44 (0) 20 7307 5454</div>
          </body>
      </html>`
      );  
  } else {
    doc.write(
    `<html style="overflow: hidden">
      <head></head>
        <body style="margin-top: 6px;margin-right: 0;">
            <div style="text-align: end;font-family: sans-serif;" class="text-end font-helvetica-neue-light" id="ah-contact-number">+44 (0) 20 7307 5454</div>
        </body>
    </html>`
    );
  }

  doc.close();
}

if (document.getElementById('footer-contact-number')) {
  const iframe = document.getElementById("footer-contact-number");
  const doc = iframe.contentDocument || iframe.contentWindow.document;

  doc.open();

  doc.write(
    `<html style="overflow: hidden">
      <head></head>
        <body style="margin-top: 6px;margin-right: 0;">
            <div style="font-family: sans-serif;" class="font-helvetica-neue-light" id="ah-contact-number">+44 (0) 20 7307 5454</div>
        </body>
    </html>`
    );

  doc.close();
}